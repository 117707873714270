import { render, staticRenderFns } from "./sosPosition.vue?vue&type=template&id=caab20f2&scoped=true&"
import script from "./sosPosition.vue?vue&type=script&lang=js&"
export * from "./sosPosition.vue?vue&type=script&lang=js&"
import style0 from "./sosPosition.vue?vue&type=style&index=0&id=caab20f2&prod&lang=css&"
import style1 from "./sosPosition.vue?vue&type=style&index=1&id=caab20f2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "caab20f2",
  null
  
)

export default component.exports